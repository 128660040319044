<template>
  <div class="px-1 text-xs sm:text-sm mt-2">
    <div class="flex justify-between items-start">
      <div class="text-text_color">
        <div class="font-bold break-words px-0.5 flex-shrink-1">
          {{ material.name }}
        </div>

        <div v-if="store?.price_visible" class="flex">
          <div :dir="material.currencyIsRtl ? 'rtl' : 'ltr'" class="font-bold">
            <span v-if="material.discountPrice">
              {{ material.discountPrice }}
            </span>
            <span v-else class="mx-1">{{ material.stringPrice }}</span>
          </div>
          <div
            v-if="material.discountPrice"
            :dir="material.currencyIsRtl ? 'rtl' : 'ltr'"
          >
            <small class="line-through text-opacity-90 mx-1">
              {{ material.stringPrice }}
            </small>
          </div>
        </div>
      </div>

      <div class="text-text_color">
        <v-icon
          v-if="store?.price_visible && store?.cart_visible"
          icon=" mdi mdi-cart-plus"
          class="rtl:-ml-1 ltr:-mr-1 custom-size-shopping-cart-domain"
          @click.stop="emit('toggleClickCart')"
        />
        <IconsCartWhatsapp
          v-else-if="!store?.cart_visible && !material.link_to_original"
          class="rtl:-ml-2 ltr:-mr-2 w-8 h-8"
          @click.stop="emit('toggleClickCart')"
        />
        <a
          v-else-if="!store?.cart_visible && material.link_to_original"
          :href="material.link_to_original"
          target="_blank"
          @click.stop
        >
          <v-icon icon="mdi mdi-open-in-new" size="24px" />
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Material } from '~~/composables/useMenuModel'
withDefaults(
  defineProps<{
    material: Material
  }>(),
  {}
)
const emit = defineEmits(['toggleClickCart'])

const { store } = useDomainState()
</script>

<style scoped>
.v-icon--size-default.custom-size-shopping-cart-domain {
  @apply text-[24px]  sm:text-[28px]  !important;
}
</style>
